.root {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #f15b2d;
  --rdp-background-color: #ef92763c;
  --rdp-accent-color-dark: #f15b2d;
  --rdp-background-color-dark: #f15b2d3c;
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */

  margin-bottom: 12px;
  color: #2c2c2c;
  user-select: none;
}

/* Hide elements for devices that are not screen readers */
.vhidden {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute !important;
  top: 0;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
}

/* Buttons */
.button_reset {
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: inherit;
  background: none;
  font: inherit;

  -moz-appearance: none;
  -webkit-appearance: none;
}

.button_reset:focus-visible {
  /* Make sure to reset outline only when :focus-visible is supported */
  outline: none;
}

.button {
  border: 2px solid transparent;
}

.button[disabled]:not(.day_selected) {
  opacity: 0.25;
}

.button:not([disabled]) {
  cursor: pointer;
}

.button:focus-visible:not([disabled]) {
  color: inherit;
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
}

.button:hover:not([disabled]):not(.day_selected) {
  background-color: var(--rdp-background-color);
}

.months {
  display: flex;
}

.month {
  /* margin: 0 1em; */
}

.month:first-child {
  margin-left: 0;
}

.month:last-child {
  margin-right: 0;
}

.table {
  margin-left: 15px;
  margin-right: 15px;
  margin-top:16px;
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
  /* border-right:1px solid red */
}

.with_weeknumber .table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;
}

.multiple_months .caption {
  position: relative;
  display: block;
  text-align: center;
}

.caption_dropdowns {
  position: relative;
  display: inline-flex;
}

.caption_label {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;
  color: currentColor;
  border: 0;
  border: 2px solid transparent;
  font-family: inherit;
  font-size: 140%;
  font-weight: 600;
}

.nav {
  white-space: nowrap;
}

.multiple_months .caption_start .nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.multiple_months .caption_end .nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.nav_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  padding: 0.5em;
  border-radius: 100%;
  color: #e2e2e2;
}
.nav_button svg {
  width: 20px;
  height: 20px;
}

.nav_button:hover {
  color: #8e8e8e;
  background-color: #f5f5f5 !important;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.dropdown_year,
.dropdown_month {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.dropdown {
  appearance: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  opacity: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.dropdown:focus-visible:not([disabled]) + .caption_label {
  background-color: var(--rdp-background-color);
  border: var(--rdp-outline);
  border-radius: 6px;
}

.dropdown_icon {
  margin: 0 0 0 5px;
}

.head {
  border: 0;
}

.head_row,
.row {
  height: 100%;
}

.head_cell {
  vertical-align: middle;
  text-transform: uppercase;
  text-align: center;
  /* height: 100%; */
  /* height: var(--rdp-cell-size); */
  padding: 0;
  padding-bottom: 8px;
  color: var(--old-greys-grey-500, #b3b3b3);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.tbody {
  border: 0;
}

.tfoot {
  margin: 0.5em;
}

.cell {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  padding-bottom: 2px;
  text-align: center;
}

.weeknumber {
  font-size: 0.75em;
}

.weeknumber,
.day {
  display: flex;
  /* overflow: hidden; */
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  margin: 0;
  border: 2px solid transparent;
  border-radius: 100%;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.5px;
}

.day_today:not(.day_outside) {
  font-weight: bold;
}

.day_selected,
.day_selected:focus-visible,
.day_selected:hover {
  color: white;
  opacity: 1;
  background-color: var(--rdp-accent-color) !important;
}

.day_outside {
  opacity: 0.5;
}

.day_selected.day_outside {
  opacity: 1;
}

.day_selected:focus-visible {
  /* Since the background is the same use again the outline */
  outline: var(--rdp-outline);
  outline-offset: 2px;
  z-index: 1;
}

.root:not([dir="rtl"]) .day_range_start:not(.day_range_end)::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.root:not([dir="rtl"]) .day_range_end:not(.day_range_start)::after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.root[dir="rtl"] .day_range_start:not(.day_range_end)::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.root[dir="rtl"] .day_range_end:not(.day_range_start)::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.day_range_end.day_range_start {
  border-radius: 100%;
  position: relative;
}
.day_range_end.day_range_start::before {
  border-radius: 100% !important;
}

.day_range_start.day_range_end::after {
  border-radius: 100% !important;
}

.day_selected.day_range_end::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  z-index: -1;
  background-color: #fef7f5;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.day_selected.day_range_start::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  z-index: -1;
  background-color: #fef7f5;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.day_selected.day_range_middle {
  color: black;
  opacity: 1;
  background-color: #fef7f5 !important;
}

.day_range_middle {
  border-radius: 0;
}
