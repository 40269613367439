.activeItem:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: 3px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #f15b2e;
}
