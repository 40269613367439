.loading {
  width: 100%;
  position: sticky;
  top: 44px;
  left: 0;
  z-index: 20;
  display: block;
}
.loading span {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  background: #f15b2e;
  background: linear-gradient(to right, #f15b2e, #ee327b);
  animation: loading infinite 5s ease;
  transform: translate3d(-100%, 0, 0);
}

.strip tr:nth-child(even) {
  background: #eee;
}
.hover tr:hover {
  background: #e2e2e2;
}
.border tr td {
  border-bottom: 1px solid #eaeaea;
}

@keyframes loading {
  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}
