.custom-calendar {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #f05a2d;
  --rdp-background-color: #e5e7eb;
  --rdp-accent-color-dark: #f05a2d;
  --rdp-background-color-dark: #1e293b;
  font-family: inherit;
}

.custom-calendar .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #fef7f5;
}

.custom-calendar .rdp-day_selected:hover {
  background-color: var(--rdp-accent-color-dark);
}

.custom-calendar .rdp-head_cell {
  font-weight: 600;
  color: #4b5563;
}

.custom-calendar .rdp-nav_button:hover {
  background-color: var(--rdp-background-color);
}
