@tailwind base;
@tailwind components;
@tailwind utilities;

.Confirmed {
  @apply text-green font-semibold;
}
.Pending {
  @apply text-orange;
}
.Canceled {
  @apply text-red font-semibold;
}
.Refund {
  @apply text-blue font-semibold;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: 'Normal';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: 'Large';
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: 'Huge';
  font-size: 18px !important;
}

.ql-size .ql-picker-label::before {
  content: attr(data-value);
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@import "./css//pagination.css";

.tableDiv::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.tableDiv::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.tableDiv::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.tableDiv::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* checkbox color */
input[type="checkbox"] {
  @apply accent-primary;
}

.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  /* background: #fefcfc; */
  border-color: #eaeaea;
}

.ql-toolbar {
  /* background: #eaecec; */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-color: #eaeaea;
  border-bottom: none;
}

.ql-toolbar.ql-snow {
  border-color: #eaeaea;
}
.ql-container.ql-snow {
  border-color: #eaeaea;
}

.ql-toolbar.ql-snow .ql-formats button:hover {
  color: #f25b2f !important;
}
/* toggle button */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
}

.switch input:checked + .slider {
  background-color: #f25b2f;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #f25b2f;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.ticket {
  /* background: #fff; */
  position: relative;
  border-radius: 12px;
  border: 1px solid #eee;
  background: #f2f2f2;
}

.modal-size {
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  /* height:  -webkit-max-content; */
  height: -webkit-max-content;
  height: max-content;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.highlighted {
  animation: highlighted linear 6s 1;
}

@keyframes highlighted {
  0% {
    @apply bg-gray-100/90;
  }
  90% {
    @apply bg-gray-100/90;
  }
  100% {
    @apply bg-white;
  }
}

svg * {
  outline: none;
}
