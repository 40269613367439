


.pagination-container .arrow:before {
  position: relative;
  content: '';
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid #B3B3B3;
  border-top: 0.12em solid #B3B3B3;
}
.pagination-container .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination-container .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .arrow.disabled::before {
  border-right: 0.12em solid #e3dbdb;
  border-top: 0.12em solid #e3dbdb;
}
